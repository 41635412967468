
<template>
  <div>   
      <v-divider class="ma-auto mb-5" width="90%"></v-divider>
      <v-card>
        <v-card-title style="word-break: break-word;">
          <div class="text-overline"><h3>{{item.title}}</h3></div>
        </v-card-title>
        <v-card-text v-if="item.description" style="word-break: break-word;">
          <div class="text-caption">{{item.description}}</div>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'TextItem',
  props: {
    item: {
      type: Object,
      default: {}
    },
    read_only: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>